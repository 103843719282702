import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";

const HeroImage = () => {
  return (
    <h1>
      <div class="relative md:block text-white">
        <StaticImage src="../images/recruit/recruit_top.png" />

        <div
          class="absolute top-0 text-left"
          style={{ marginTop: "8.5%", marginLeft: "10%" }}
        >
          <div class="text-sm md:text-2xl lg:text-3xl font-bold">採用情報</div>
          <div class="text-xs md:text-base lg:text-lg font-semibold">
            RECRUIT
          </div>
        </div>
      </div>
    </h1>
  );
};

const centered =
  "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ";
const caption_text = "text-white text-3xl font-bold whitespace-nowrap";

const RecruitPage = () => {
  return (
    <Layout>
      <div class="container mx-auto text-center">
        <HeroImage />

        <div class="w-full">
          <div class="w-11/12 mx-auto text-center text-sm md:text-base md:px-2">
            <h2 class="py-8 text-center">
              <div class="text-darkblue text-2xl font-semibold">採用情報</div>
              <div class="text-secondary text-lg">RECRUIT</div>
            </h2>
            <div className="mb-12">
              <p>現在、採用は行っておりません。</p>
            </div>
            <div className="flex flex-col md:flex-row md:justify-around mb-12">
              <div className="relative w-full md:w-5/12 mb-4 hover:opacity-70">
                <Link to="/recruit">
                  <StaticImage
                    src="../images/recruit/new_graduate.png"
                    layout="fullWidth"
                  />
                  <div className={centered + caption_text}>
                    新卒採用はこちら
                  </div>
                </Link>
              </div>

              <div className="relative w-full md:w-5/12 mb-4 hover:opacity-70">
                <Link to="/recruit">
                  <StaticImage
                    src="../images/recruit/career.png"
                    layout="fullWidth"
                  />
                  <div className={centered + caption_text}>
                    キャリア採用はこちら
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default RecruitPage;
